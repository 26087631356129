import { adminRoot } from "constants/defaultValues/defaultValues"

export default [
  {
    id: "ticketing",
    label: "Ticketing",
    to: `${adminRoot}`,
    icon: "iconsminds-ticket",
    subs: [
      {
        id: "ticketing.events",
        label: "Events",
        icon: "iconsminds-calendar-4",
        to: `${adminRoot}module/140`,
      },
    ],
  },
]
