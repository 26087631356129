import TicketingEvents from "./modules/ticketing/events/TicketingEvents"

// chooses the Module to show based on module ID
const ModulePicker = ({ ID }) => {
  if (ID === "140") {
    return <TicketingEvents />
  }

  return <div>{ID}</div>
}

export default ModulePicker
