import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import { withRouter } from "react-router-dom"
import TicketingEventsForm from "./_tabs/TicketingEventsForm"
import TicketingEventsTable from "./_tabs/TicketingEventsTable"

const TicketingEvents = () => {
  return (
    <TabModulesTemplate
      documentTabs={[
        {
          label: "Form",
          Component: opened => <TicketingEventsForm opened={opened} />,
        },
      ]}
      collectionTabs={[
        {
          label: "Table",
          Component: () => <TicketingEventsTable />,
        },
      ]}
    />
  )
}

export default withRouter(TicketingEvents)
