import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { auth } from "../../helpers/Firebase"
import {
  FORGOT_PASSWORD,
  GET_USER_INFO,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  RESET_PASSWORD,
} from "./actionTypes"

import {
  forgotPasswordError,
  forgotPasswordSuccess,
  getUserInfoNoUser,
  getUserInfoSuccess,
  loginUserError,
  loginUserSuccess,
  registerUserError,
  registerUserSuccess,
  resetPasswordError,
  resetPasswordSuccess,
} from "./actions"

import {
  adminRoot,
  applicationSubdomain,
  UserRole,
} from "../../constants/defaultValues/defaultValues"

import ApplicationSpecificConfig from "applications/ApplicationSpecificConfig"
import {
  getDataFromFirestoreCollection,
  getFirestoreDocument,
} from "helpers/Firestore"

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword)
}

const loginWithEmailPasswordAsync = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then(user => user)
    .catch(error => error)

const getUserDataAsync = async uid => {
  const userDatabaseInfo = await getFirestoreDocument({
    path: `Users/CMSAdmins/DataBase/${uid}`,
  })

  const userInfo = {
    MetaData: userDatabaseInfo,
  }

  userInfo.avatarURL = "/avatar_placeholder.png"

  userInfo.uid = uid

  userInfo.role = UserRole[userDatabaseInfo.Role_Name]

  if (userDatabaseInfo.Client_IDs?.[applicationSubdomain]) {
    userDatabaseInfo.Client_ID =
      userDatabaseInfo.Client_IDs[applicationSubdomain]
  }

  if (userDatabaseInfo.Client_ID) {
    const clientRoute =
      ApplicationSpecificConfig[applicationSubdomain].clientRoute
    const clientDatabaseInfo = await getFirestoreDocument({
      path: `${clientRoute}/${userDatabaseInfo.Client_ID}`,
    })

    if (clientDatabaseInfo) {
      const moduleInfo = await getDataFromFirestoreCollection({
        path: `${clientRoute}/${userDatabaseInfo.Client_ID}/Modules`,
      })

      return {
        user: userInfo,
        client: {
          ID: userDatabaseInfo.Client_ID,
          MetaData: clientDatabaseInfo,
          ModuleInfo: moduleInfo,
        },
      }
    }

    return {
      user: userInfo,
      client: {
        MetaData: clientDatabaseInfo,
      },
    }
  } else {
    return { user: userInfo }
  }
}

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user
  const { history } = payload
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password)
    if (!loginUser.message) {
      const { user, client } = yield call(getUserDataAsync, loginUser.user.uid)
      if (!user.message) {
        yield put(loginUserSuccess(user, client))
        history.push(adminRoot)
      }
    } else {
      yield put(loginUserError(loginUser.message))
    }
  } catch (error) {
    yield put(loginUserError(error))
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword)
}

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user
  const { history } = payload
  try {
    const registerUser = auth.createUserWithEmailAndPassword(email, password)

    const { user, client } = yield call(
      getUserDataAsync,
      registerUser.MetaData.FirebaseUID
    )

    yield put(registerUserSuccess(user, client))
    history.push("/login")
  } catch (error) {
    yield put(registerUserError(error))
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout)
}

const logoutAsync = async history => {
  await auth
    .signOut()
    .then(user => user)
    .catch(error => error)
  history.push("/login")
}

function* logout({ payload }) {
  const { history } = payload
  yield call(logoutAsync, history)
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword)
}

const forgotPasswordAsync = async email => {
  return await auth
    .sendPasswordResetEmail(email)
    .then(user => user)
    .catch(error => error)
}

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email)
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess("success"))
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message))
    }
  } catch (error) {
    yield put(forgotPasswordError(error))
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword)
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then(user => user)
    .catch(error => error)
}

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    )
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess("success"))
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message))
    }
  } catch (error) {
    yield put(resetPasswordError(error))
  }
}

export function* watchGetUserInfo() {
  yield takeEvery(GET_USER_INFO, getUserInfo)
}

function* getUserInfo({ payload }) {
  const userUID = payload
  if (userUID) {
    const { user, client } = yield call(getUserDataAsync, userUID)

    if (!user.message) {
      yield put(getUserInfoSuccess(user, client))
    } else {
      yield put(loginUserError(user.message))
    }
  } else {
    yield put(getUserInfoNoUser())
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetUserInfo),
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ])
}
