// business

// city
import _cityAccountEditorRoutes from "./city/account-editor/_cityAccountEditorRoutes"
import _cityAccountManagerRoutes from "./city/account-manager/_cityAccountManagerRoutes"
import cityMenuItems from "./city/helpers/menuItems"
import {
  makeAccountEditorMenu as cityMakeAccountEditorMenu,
  makeAccountManagerMenu as cityMakeAccountManagerMenu,
} from "./city/helpers/Utils"
import CityModulePicker from "./city/user/ModulePicker"

// ticketing
import ticketingMenuItems from "./ticketing/helpers/menuItems"
import TicketingModulePicker from "./ticketing/user/ModulePicker"

export default {
  city: {
    makeAccountEditorMenu: cityMakeAccountEditorMenu, // list of menu items for the account editor tab
    makeAccountManagerMenu: cityMakeAccountManagerMenu, // list of menu items for the account manager tab
    accountEditorRoutes: _cityAccountEditorRoutes, // routes for account editor tab
    accountManagerRoutes: _cityAccountManagerRoutes, // routes for account manager tab
    ModulePicker: CityModulePicker, // module picker for app
    style: "city", // style for the application to use, "default" for the default one. check guide in readme for how to make custom
    clientRoute: "City/Cities/DataBase", // route to the client data in firestore
    menuItems: cityMenuItems,
  },
  ticketing: {
    makeAccountEditorMenu: () => [],
    makeAccountManagerMenu: () => [],
    accountEditorRoutes: [],
    accountManagerRoutes: [],
    ModulePicker: TicketingModulePicker,
    style: "city",
    clientRoute: "City/Modules/DataBase/140/Organizers",
    menuItems: ticketingMenuItems,
  },
}
