import DateTime from "components/ModuleElements/Form/Fields/FieldTypes/DateTime"
import FormModule from "components/Modules/Documents/FormModule"
import {
  createFirestoreDocument,
  createOrUpdateFirestoreDocument,
  deleteFirestoreDocument,
  firestoreBatchCommit,
  getDataFromFirestoreCollection,
  getFirestoreDocument,
} from "helpers/Firestore"

const TicketingEventsForm = ({ opened }) => {
  const fields = [
    {
      ID: "1",
      Name: "Title",
      Label: "Title",
      Type: "Text",
      Required: true,
      Prompt: "Title is required",
    },
    {
      ID: "2",
      Type: "Text",
      FieldType: "textarea",
      Name: "ShortText",
      Label: "Short text",
    },
    {
      ID: "3",
      Type: "Rich Text Field",
      Name: "FullText",
      Label: "Text",
    },
    {
      ID: "4",
      Type: "Single Image",
      Name: "Picture",
      Label: "Picture",
      Path: "News/",
    },
    {
      ID: "5",
      Name: "URL",
      Label: "URL",
      Type: "Text",
    },
    {
      ID: "7",
      Name: "DiscountText",
      Label: "Discount text",
      Type: "Text",
    },
    {
      ID: "8",
      Name: "Discount",
      Label: "Discount",
      Type: "Text",
      FieldType: "number",
    },
    {
      ID: "9",
      Name: "MaxTickets",
      Label: "Max tickets",
      Type: "Text",
      FieldType: "number",
    },
    {
      ID: "10",
      Type: "Editable Table",
      Name: "Schedule",
      DeletedName: "DeletedSchedule",
      Label: "Schedule",
      Columns: [
        // {
        //   Name: "ID",
        //   Label: "ID",
        // },
        {
          Name: "StartDatetime",
          Label: "Start Time",
          Editable: true,
          Type: "datetime",
        },
      ],
      Required: false,
      Creatable: true,
      Orderable: false,
    },
  ]

  return (
    <FormModule
      form={{
        title: "Events",
        fields: fields,
        fieldsToTranslate: ["Title", "ShortText", "FullText"],
        getDefaultValues: async ({ defaultLanguage }) => {
          if (!opened)
            return {
              OriginalLanguage: defaultLanguage,
              Schedule: [],
              DeletedSchedule: [],
            }

          const data = await getFirestoreDocument({
            path: `City/Modules/DataBase/140/Events/${opened}`,
          })

          const schedule = await getDataFromFirestoreCollection({
            path: `City/Modules/DataBase/140/EventsSchedule`,
            where: [
              {
                key: "Event_ID",
                operation: "==",
                value: opened,
              },
            ],
            timestampKeys: ["StartDatetime"],
          })

          return { ...data, Schedule: schedule, DeletedSchedule: [] }
        },
        onSubmit: async (values, { clientMetaData }) => {
          const event_data = { ...values, Cities: clientMetaData.Cities }
          delete event_data.Schedule
          delete event_data.DeletedSchedule

          const event = await createOrUpdateFirestoreDocument({
            path: `City/Modules/DataBase/140/Events`,
            values: { ...event_data },
          })

          const schedule = await firestoreBatchCommit({
            commits: [
              ...values.Schedule.map((schedule, index) => {
                if (schedule.ID.startsWith("NEW")) {
                  return {
                    type: "add",
                    path: `City/Modules/DataBase/140/EventsSchedule`,
                    values: {
                      Event_ID: event.ID,
                      StartDatetime: schedule.StartDatetime,
                      Event_Data: event_data,
                    },
                  }
                }

                return {
                  type: "update",
                  path: `City/Modules/DataBase/140/EventsSchedule/${schedule.ID}`,
                  values: {
                    Event_ID: event.ID,
                    StartDatetime: schedule.StartDatetime,
                    Event_Data: event_data,
                    ID: schedule.ID,
                  },
                }
              }),
              ...values.DeletedSchedule.map(deletedSchedule => ({
                type: "delete",
                path: `City/Modules/DataBase/140/EventsSchedule/${deletedSchedule}`,
              })),
            ],
            timestampKeys: ["StartDatetime"],
          })

          return {
            ...event,
            Schedule: schedule.newValues,
            DeletedSchedule: [],
          }
        },
        onDelete: async ({ values }) => {
          // Delete all schedules
          await firestoreBatchCommit({
            commits: [
              ...values.Schedule.map(schedule => ({
                type: "delete",
                path: `City/Modules/DataBase/140/EventsSchedule/${schedule.ID}`,
              })),
              ...values.DeletedSchedule.map(deletedSchedule => ({
                type: "delete",
                path: `City/Modules/DataBase/140/EventsSchedule/${deletedSchedule}`,
              })),
            ],
          })

          return await deleteFirestoreDocument({
            path: `City/Modules/DataBase/140/Events/${opened}`,
          })
        },
      }}
      ID={opened}
    />
  )
}

export default TicketingEventsForm
