import TableModule from "components/Modules/Collections/TableModule"
import { getDataFromFirestoreCollection } from "helpers/Firestore"
import React from "react"

const CityDigitalServicesTable = () => {
  return (
    <TableModule
      getData={async ({ firebaseUID }) => {
        const data =  await getDataFromFirestoreCollection({
          path: `City/Cities/DataBase/${firebaseUID}/Modules/141/DataBase`,
        })

        console.log("data", data)

        return data
      }}
      columns={[{ key: "Name", label: "Name" }]}
      searchKey={"Name"}
    />
  )
}

export default CityDigitalServicesTable
