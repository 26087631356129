import TabModulesTemplate from "components/Modules/TabModulesTemplate"
import { withRouter } from "react-router-dom"
import CityDigitalServicesForm from "./_tabs/CityDigitalServicesForm"
import CityDigitalServicesTable from "./_tabs/CityDigitalServicesTable"

const CityDigitalServices = () => {
  return (
    <TabModulesTemplate
      documentTabs={[
        {
          label: "Form",
          Component: opened => <CityDigitalServicesForm opened={opened} />,
        },
      ]}
      collectionTabs={[
        {
          label: "Table",
          Component: () => <CityDigitalServicesTable />,
        },
      ]}
    />
  )
}

export default withRouter(CityDigitalServices)
