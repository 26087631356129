import FormModule from "components/Modules/Documents/FormModule"
import {
  createOrUpdateFirestoreDocument,
  deleteFirestoreDocument,
  getFirestoreDocument,
} from "helpers/Firestore"

const CityDigitalServicesForm = ({ opened }) => {
  return (
    <FormModule
      form={{
        title: "Digital Services",
        fields: [
          {
            ID: "1",
            Name: "Name",
            Label: "Name",
            Type: "Text",
            Required: true,
            Prompt: "Name is required",
          },
          {
            ID: "2",
            Name: "Description",
            Label: "Description",
            FieldType: "textarea",
            Type: "Text",
          },
          {
            ID: "3",
            Type: "Single Image",
            Name: "LogoImage",
            Label: "Logo image",
            Path: "DigitalServices/",
            Required: true,
            Prompt: "Logo image is required",
          },
          {
            ID: "4",
            Name: "Link",
            Label: "Link",
            Type: "Text",
            Required: true,
            Prompt: "Link is required",
          },
          {
            ID: "5",
            Name: "LinkText",
            Label: "Link text",
            Type: "Text",
          },
        ],
        fieldsToTranslate: ["Name", "Description", "LinkText"],
        getDefaultValues: async ({ firebaseUID }) => {
          if (!opened) return {}

          const data = await getFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/141/DataBase/${opened}`,
          })

          return data
        },
        onSubmit: async (values, { firebaseUID }) => {
          return await createOrUpdateFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/141/DataBase`,
            values: { ...values },
          })
        },
        onDelete: async ({ firebaseUID }) => {
          return await deleteFirestoreDocument({
            path: `City/Cities/DataBase/${firebaseUID}/Modules/141/DataBase/${opened}`,
          })
        },
      }}
      ID={opened}
    />
  )
}

export default CityDigitalServicesForm
