import React from "react"
import CityCampaigns from "./modules/campaigns/campaigns/CityCampaigns"
import CityPartners from "./modules/campaigns/partners/CityPartners"
import CityPromotions from "./modules/campaigns/promotions/CityPromotions"
import CityAskALocal from "./modules/myCity/askALocal/CityAskALocal"
import CityMayorsDiary from "./modules/myCity/mayorsDiary/CityMayorsDiary"
import CityNews from "./modules/myCity/news/CityNews"
import CityProblemsReport from "./modules/myCity/problemsReport/CityProblemsReport"
import CitySurveys from "./modules/myCity/surveys/CitySurveys"
import CitySport from "./modules/sportClubs/CitySport"
import CityVisibility from "./modules/visibility/CityVisibility"
import CityAirQuality from "./modules/weather/airQuality/CityAirQuality"
import CityWeather from "./modules/weather/weather/CityWeather"
import CityEmergencyCommunication from "./modules/myCity/emergencyCommunication/CityEmergencyCommunication"
import CityAddressBook from "./modules/myCity/addressBook/CityAddressBook"
import CityBudgetProposals from "./modules/myCity/budgetProposals/CityBudgetProposals"
import CityDigitalServices from "./modules/myCity/digitalServices/CityDigitalServices"

// chooses the Module to show based on module ID
const ModulePicker = ({ ID }) => {
  if (ID === "83") {
    return <CityNews />
  }

  if (ID === "56") {
    return <CitySurveys />
  }

  if (ID === "13") {
    return <CityMayorsDiary />
  }

  if (ID === "35") {
    return <CityProblemsReport />
  }

  if (ID === "107") {
    return <CityVisibility />
  }

  if (ID === "109") {
    return <CityWeather />
  }

  if (ID === "110") {
    return <CityAirQuality />
  }

  if (ID === "111") {
    return <CityAskALocal />
  }

  if (ID === "114") {
    return <CitySport type={"Soccer"} typeID={"114"} />
  }

  if (ID === "115") {
    return <CitySport type={"Basketball"} typeID={"115"} />
  }

  if (ID === "123") {
    return <CityCampaigns />
  }

  if (ID === "124") {
    return <CityPromotions />
  }

  if (ID === "128") {
    return <CityPartners />
  }

  if (ID === "132") {
    return <CityEmergencyCommunication />
  }

  if (ID === "133") {
    return <CityAddressBook />
  }

  if (ID === "137") {
    return <CityBudgetProposals />
  }

  if (ID === "141") {
    return <CityDigitalServices />
  }

  return <div>{ID}</div>
}

export default ModulePicker
